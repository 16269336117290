<template>
  <main>
    <Header />
    <div class="sentence">
        <!--<img src="./../assets/title-sorteo.svg" width="45%" alt="osfatun" />-->
        <h3>Sorteo Semanal - Campaña de Verano 2025 OSFATUN</h3>
    </div>
    <section class="content">
      <h3 class="title mb-5 has-text-centered">
        Para participar del sorteo completá el siguiente formulario:
      </h3>
      <hr class="hr" />
      <Form />
    </section>
    <Footer />
  </main>
</template>

<script>
import Form from "./../components/form/Form.vue";
import Header from "../components/header/Header.vue";
import Footer from "../components/footer/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Form,
  },
  metaInfo: {
    titleTemplate: "%s | Home",
  },
};
</script>

<style scoped>
.sentence {
  position: relative;
  width: 100%;
  height: 75vh; /* Ajusta la altura según tus necesidades */
  background-image: url("./../assets/background.png");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.sentence::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(55, 186, 229, 0.6); /* Cambia el color y la opacidad según tus necesidades */
}

.sentence h3 {
  padding-top: 20%;
  text-align: center;
  position: relative;
  margin: 0 auto;
  display: block;
  font-weight: bold;
  font-size: 36px;
  color: #ffffff;
}

.sentence img {
  margin: 0 auto;
  padding-top: 20%;
  text-align: center;
  display: block;
  position: relative;
}
.content {
  position: relative;
  top: -15vh;
  background: white;
  max-width: 83%;
  padding: 40px 30px;
  margin: 0 auto;
}
h3.title {
  font-weight: bold;
  font-size: 24px;
  color: #00758f;
  line-height: 1.9rem;
  margin: 0 50px;
}
.second-container {
  max-width: 90%;
  margin: 0 auto;
}
.hr {
  background-color: #eeeeee;
  margin: 50px 0;
}
h1.title {
  font-size: 49px;
  color: #00758f;
  margin-bottom: 6%;
}
@media screen and (max-width: 42em) {
  .sentence {
    height: 40vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .sentence h3 {
    padding-top: 45%;
    text-align: center;
    position: relative;
    margin: 0 auto;
    display: block;
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
  }
  .sentence img {
    position: absolute;
    left: 0;
    right: 0;
    top: 16%;
    width: 90%;
    /* padding-top: 30%; */
    text-align: center;
    display: block;
  }
  .content {
    position: relative;
    top: 0;
    background: white;
    max-width: 85%;
    padding: 40px 30px;
    margin: 0 auto;
  }
  .prestadoresForm {
    max-width: 80%;
  }
  h3.title {
    font-size: 20px;
    margin: 0;
  }
}
</style>
