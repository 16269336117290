<template>
  <form @submit="sendForm" class="form">
    <section class="container-fluid">
      <div class="columns is-multiline is-mobile">
        <div class="column is-12-desktop">
          <ul class="oneLine">
            <li>
              <InputUl
                :width="'550px'"
                :inp-type="'text'"
                :inp-name="'fName'"
                :inp-title-start="'Nombre y apellido'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'1'"
                @input="formNameChange"
              />
            </li>
          </ul>
        </div>
        <div class="column is-12-desktop">
          <ul class="oneLine">
            <li>
              <InputUl
                :width="'300px'"
                :inp-type="'email'"
                :inp-name="'fEmail'"
                :inp-title-start="'Email'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'3'"
                @input="formEmailChange"
              />
            </li>
            <li>
              <InputUl
                :width="'300px'"
                :inp-type="'number'"
                :inp-name="'fCel'"
                :inp-title-start="'Celular'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'4'"
                @input="formCelChange"
              />
            </li>
          </ul>
        </div>
        <div class="column is-12-desktop">
          <ul class="oneLine">
            <!--<li>
             <InputUl
              :width="'284px'"
              :inp-type="'select'"
              :inp-name="'fCuenta'"
              :inp-title-start="'Tipo de cuenta'"
              :data="dataCuentas"
              :inp-data-name="'departaments'"
           
              :inp-required="true"
              :inp-tab="'8'"
              v-model="formData.accountType"
              @change="formCuentaChange"
            />
            </li>
             <li>
              <InputUl
                :width="'300px'"
                :inp-type="'text'"
                :inp-name="'fUsername'"
                :inp-title-start="'Nombre de Usuario'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'6'"
                @input="username"
              />
            </li>-->
            <li>
              <InputUl
                :width="'300px'"
                :inp-type="'text'"
                :inp-name="'fage'"
                :inp-title-start="'Edad'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'6'"
                @input="age"
              />
            </li>
            <li>
              <InputUl
                :width="'300px'"
                :inp-type="'text'"
                :inp-name="'fcuil'"
                :inp-title-start="'DNI'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'6'"
                @input="cuil"
              />
            </li>
            <li>
              <InputUl
                :width="'300px'"
                :inp-type="'text'"
                :inp-name="'fHealthCoverage'"
                :inp-title-start="'Cobertura de salud actual'"
                :inp-title-end="''"
                :inp-required="true"
                :inp-tab="'6'"
                @input="healthCoverage"
              />
            </li>
          </ul>
        </div>
        <div class="column is-12-mobile is-6-desktop">
          <div class="margin-auto">
            <InputUl
              :width="'284px'"
              :inp-type="'select'"
              :inp-name="'fProvince'"
              :inp-title-start="'Provincia'"
              :data="dataProvince"
              :inp-data-name="'departaments'"
              :inp-title-end="''"
              :inp-required="true"
              :inp-tab="'8'"
              @change="formProvinciaChange"
            />
          </div>
        </div>
        <div class="column is-12-mobile is-6-desktop">
          <div class="margin-auto">
            <InputUl
              :width="'284px'"
              :inp-type="'select'"
              :inp-name="'fCity'"
              :inp-title-start="'Localidad'"
              :inp-title-end="''"
              :data="dataCity"
              :inp-data-name="'departaments'"
              :inp-required="true"
              :inp-tab="'9'"
              @change="formLocalidadChange"
            />
          </div>
        </div>
      </div>

      <div class="column is-12">
        <template v-if="loading">
          <button class="button is-loading">Enviar</button>
        </template>
        <template v-else>
          <button class="button" type="submit">Enviar</button>
        </template>
      </div>
    </section>
  </form>
</template>

<script>
import axios from "axios";
import InputUl from "./InputUl";
import geography from "./../../assets/data/geography.json";
export default {
  name: "Form",
  components: { InputUl },
  data() {
    return {
      loading: false,
      baseUrl: "https://api.osfatun.com.ar/",
     // baseUrl: "http://osfatunapi2.test/",
      dataDepartament: [],
      dataProvince: [],
      dataCuentas: [
   { id: "Facebook", name: "Facebook" },
      { id: "Instagram", name: "Instagram" }
      
    ],
    dataCity: [],
     formData: {
      cel: "",
      city: "",
      email: "",
      fullname: "",
      province: "",
      accountType: "",
      healthCoverage: "",
      username: "",
      age: "",
      cuil: "",
      friends: [],
    },
    };
  },
  created() {
    this.getProvince();
    this.setDefaultAccountType();
    
  },
  mounted() {
    document.querySelector("select[name='inpName']").selectedIndex = 1; //instagram
    this.formData.tipocuenta = "Instagram";
  },
  methods: {
    async sendForm(e) {
      e.preventDefault();
      this.loading = true;
      const data = new FormData();
      data.append("origin", "sorteo");
      data.append("fullname", this.formData.fullname);
      data.append("email", this.formData.email);
      data.append("phone", this.formData.cel);
      data.append("province", this.formData.province);
      data.append("type", "potencial afiliado");
      data.append("city", this.formData.city);
      data.append("social_account", this.formData.accountType);
      data.append("coverage", this.formData.healthCoverage);
      data.append("username", this.formData.username);
      data.append("age", this.formData.age);
      data.append("cuil", this.formData.cuil);

      try {
        await axios.post(this.baseUrl + "api/contacts", data);
        this.loading = false;
        this.makeAllInputEmpty();
        this.$buefy.dialog.alert({
          message: "Muchas gracias! <br /> Ya estás participando del sorteo",
          type: "is-info",
        });
      } catch (err) {
        this.loading = false;
        this.makeAllInputEmpty();
        if (err.response) {
          if (err.response.status === 422) {
            console.log("El usuario ya existe");
          }
        }
      }
    },
    getProvince() {
      this.dataProvince = [];
      geography.forEach(({ id, nombre }) => {
        this.dataProvince.push({ id, name: nombre });
      });
      console.log( this.dataProvince)
    },
    getCity() {
      this.dataCity = [];
      geography.forEach(({ nombre, ciudades }) => {
        if (this.formData.province === nombre) {
          ciudades.forEach(({ id, nombre }) => {
            this.dataCity.push({ id, name: nombre });
          });
        }
      });
    },
    formChangeNames(value, name) {
      let id = name.replace("fName", "");
      let existe = false;
      this.formData.friends.forEach((item) => {
        if (item.id === id) {
          item.fullname = value;
          existe = true;
        }
      });
      if (!existe) {
        this.formData.friends.push({ id: id, fullname: value, email: "" });
      }
    },
    formChangeEmails(value, name) {
      let id = name.replace("fEmail", "");
      let existe = false;
      this.formData.friends.forEach((item) => {
        if (item.id === id) {
          item.email = value;
          existe = true;
        }
      });
      if (!existe) {
        this.formData.friends.push({ id: id, fullname: "", email: value });
      }
    },
    formTipoCuentaChange(v) {
      this.formData.tipocuenta = v;
    },
    formCuentaChange(v) {
      this.formData.accountType = v;
    },
    formCelChange(v) {
      this.formData.cel = v;
    },
    formEmailChange(v) {
      this.formData.email = v;
    },
    formNameChange(v) {
      this.formData.fullname = v;
    },
    formDniChange(v) {
      this.formData.dni = v;
    },
    formLocalidadChange(value) {
      this.formData.city = value;
    },
    formProvinciaChange(value) {
      this.formData.province = value;
      this.getCity();
    },
   
    healthCoverage(value) {
      this.formData.healthCoverage = value;
    },

    username(value) {
      this.formData.username = value;
    },

    age(value) {
      this.formData.age = value;
    },

    cuil(value) {
      this.formData.cuil = value;
    },

     setDefaultAccountType() {
      // Establece "Selecciona" como valor inicial de accountType
      this.formData.accountType = "Facebook";
    },
    
    
    makeAllInputEmpty() {
      this.formData = {
        cel: "",
        city: "",
        dni: "",
        email: "",
        cuenta: "",
        fullname: "",
        province: "",
        accountType: "",
        age: "",
        cuil: "",
        healthCoverage: "",
        friends: [],
      };
      document.querySelectorAll("select").forEach((e) => (e.selectedIndex = 0));
      document.querySelectorAll("input").forEach((e) => (e.value = ""));
    },
  },
};
</script>

<style scoped>
.oneLine {
  margin: 0;
  padding: 0;
  text-align: left;
}
.oneLine li {
  margin-right: 30px;
  display: inline-block;
}
.oneLine li:last-child {
  margin-right: 0px;
}
.form {
  max-width: 80%;
  margin: 0 auto;
}
.margin-auto {
  margin: 0 auto;
}
h3.subtitle {
  color: #00758f;
  font-weight: normal;
  text-align: left;
}
button {
  width: 195px;
  max-width: 195px !important;
  margin: 0 auto;
  background: #00758f;
  color: #fff;
  font-size: 24px;
  color: #ffffff;
  display: block;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 600;
}
button:hover {
  width: 195px;
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width: 42em) {
  .margin-auto {
    max-width: 100% !important;
  }
  h3.subtitle {
    font-size: 20px;
  }
  .oneLine {
    display: block;
  }
  .oneLine li {
    margin-right: 0;
  }
  .form {
    max-width: 100%;
  }
}
</style>
